import React, { Component } from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { style } from '../index.style';
import { formatDate } from '../../../../../utils/date';
import { IndiceSummary } from '../../../../../interfaces/indice';

interface IndexCardProps {
  indice: IndiceSummary;
}

export function IndexReferenceData({indice}: IndexCardProps) {
  if(!indice)
    return <></>;

  const price = indice.indexPrice;

  function hightLow(hight: number, low: number, separator: string = "/" ){
    return <>
      { hight != undefined ?
        hight.toFixed(5)
        : "-"
      }
      {` ${separator} `}
      { low != undefined ?
        low.toFixed(5)
        : "-"
      }
    </>;
  }
  
  function changePriceColor(price: string, variation: number) {
    return <span style={{ color: variation < 0 ? '#CB1234' : '#22AA5F' }}>{`${price}`}</span>;
  }

  function changePrice(absolute: number, percent: number, separator: string = "/" ){
    return <>
      { percent != undefined ?
        <>
          {changePriceColor(`${percent.toFixed(5)}%`, percent)}
        </>
        : "-"
      }
      {` ${separator} `}
      { absolute != undefined ?
        <> 
          {changePriceColor(absolute.toFixed(5), absolute)}
        </>
        : "-"
      }
    </>;
  }

  return (
    <div css={style.priceContainer}>
      <div css={style.pricesContainer}>
        <div css={style.flexRow}>
          <div css={style.blocTitle}>Reference data</div>
          <div css={style.blocRowSpace}>
            <div css={style.blocRowKey}>YTD</div>
            <div css={style.blocRowValue}>
              {changePrice(price?.yearToDateAbsolute, price?.yearToDatePercent)}
            </div>
          </div>
          <div css={style.blocRowSpace}>
            <div css={style.blocRowKey}>Prev. closing price</div>
            <div css={style.blocRowValue}>
              {price?.lastClosingPrice?.amount?.toFixed(5)}
              {` - `}
              {formatDate(price?.lastClosingPrice?.date)}
            </div>
          </div>
          <div css={style.blocRowSpace}>
            <div css={style.blocRowKey}>Change prev. closing price</div>
            <div css={style.blocRowValue}>
              {changePrice(price?.lastPrice?.changeAbsolute, price?.lastPrice?.changePercent)}
            </div>
          </div>
          <div css={style.blocRowSpace}>
            <div css={style.blocRowKey}>Month Hi / Lo</div>
            <div css={style.blocRowValue}>
              {hightLow(price?.monthPriceMax?.amount, price?.monthPriceMin?.amount)}
            </div>
          </div>
          <div css={style.blocRowSpace}>
            <div css={style.blocRowKey}>Year Hi / Lo</div>
            <div css={style.blocRowValue}>
              {hightLow(price?.yearPriceMax?.amount, price?.yearPriceMin?.amount)}
            </div>
          </div>
          <div css={style.blocRowSpace}>
            <div css={style.blocRowKey}>52 weeks Hi / Lo</div>
            <div css={style.blocRowValue}>
              {hightLow(price?.yearOnYearPriceMax?.amount, price?.yearOnYearPriceMin?.amount)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
