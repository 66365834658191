import React, { useEffect, useState } from "react";
import { css, jsx } from "@emotion/react";

import FilterButton from "../../Search/results/searchMobile/filterButton";
import Loader from "../../Loader/loader.component";
import { MarketNewsTagFilter } from "../MarketNews/Filter/market-news-tag-filter.component";
import MobileFilterContainer from "../../Search/results/searchMobile/mobileFilterContainer";
import { SearchInput } from "../../Search/input";
import { SearchMobile } from "../../UI/list/base/index-mobile";
import { SearchTable } from "../../UI/list/base";
import TradingNewsCard from "./card";
import TradingNewsHeader from "./header";
import TradingNewsRow from "./row";
import { genericStyle } from "../../UI/generic/generic.style";
import { marketNewsStyle } from "../MarketNews/index.style";
import { canUseDOM } from "../../../../predicates";
import ResultsIndicator from "../../Search/results/searchMobile/resultsIndicator";
import {
  SEARCH_EQUITIES_WITH_BBO,
  SEARCH_FIXED_INCOME_WITH_BBO,
  SEARCH_FUNDS_WITH_BBO,
  SEARCH_LGX_SUSTAINABLE_BONDS,
  SEARCH_RENMINBI_BONDS,
} from "../../../../graphql";
import { luxseGatewayGraphqlClient } from "../../../../luxse-gateway-graphql-client";
import { SecuritySearchResultDto } from "../../../../interfaces";
import MarketTradingFilters from "./filters";
import { TYPE_NEWS } from "../MarketNews";
import Utils from "../../../utils/utils";

/** @jsx jsx */

export enum TradingNewsFilterEnum {
  ONE_DAY_AGO = "ONE_DAY_AGO",
  ONE_WEEK_AGO = "ONE_WEEK_AGO",
  TWO_WEEKS_AGO = "TWO_WEEKS_AGO",
  MORE_THAN_TWO_WEEKS = "MORE_THAN_TWO_WEEKS",
  CALENDAR_FILTER_SIZE = "CALENDAR_FILTER_SIZE",
  equities = "equities",
  fixedIncome = "fixedIncome",
  lgx = "lgx",
  renminbiBonds = "renminbiBonds",
  funds = "funds",
}

/**
 * page "/trading-news"
 * @param props
 * @constructor
 */
export function TradingNewsView(props: any) {
  // filter value coming from /market-data
  let filterFromDashboard: any = TradingNewsFilterEnum.equities;
  if (canUseDOM) {
    const search = new URLSearchParams(window?.location?.search);
    const searchType = search?.get("type");
    if (searchType && (searchType as TradingNewsFilterEnum)) {
      filterFromDashboard = searchType as TradingNewsFilterEnum;
    }
  }

  let baseFilters: any = [
    {
      type: TradingNewsFilterEnum.equities,
      label: "Equities",
      totalHits: 0,
      loader: true,
    },
    {
      type: TradingNewsFilterEnum.fixedIncome,
      label: "Fixed Incomes",
      totalHits: 0,
      loader: true,
    },
    {
      type: TradingNewsFilterEnum.lgx,
      label: "Sustainable Bonds",
      totalHits: 0,
      loader: true,
    },
    {
      type: TradingNewsFilterEnum.renminbiBonds,
      label: "Renminbi Bonds",
      totalHits: 0,
      loader: true,
    },
    {
      type: TradingNewsFilterEnum.funds,
      label: "Funds",
      totalHits: 0,
      loader: true,
    },
  ];
  const [filters, setFilters] = useState<any[]>(baseFilters);

  function getFilterLabel(type: TradingNewsFilterEnum) {
    let label: string = type;
    baseFilters.map((filter: any) => {
      if (filter.type === type) label = filter.label;
    });
    return label;
  }

  function updateFiltersNumber(type: TradingNewsFilterEnum, totalHits: number) {
    baseFilters = baseFilters.map((filter: any) => {
      if (filter.type === type)
        return { ...filter, totalHits: totalHits, loader: false };

      return filter;
    });

    setFilters(baseFilters);
  }

  function checkLoader() {
    let loaderTmp = false;
    baseFilters.map((filter: any) => {
      loaderTmp = loaderTmp || filter.loader;
    });

    setLoader(loaderTmp);
  }

  const [pageNumber, setPageNumber] = useState<number>(0);
  const pageSize = 20;
  const [results, setResults] = useState<SecuritySearchResultDto>(null);

  const [loader, setLoader] = useState<boolean>(true);
  const [pageLoader, setPageLoader] = useState<boolean>(true);
  const [filterToDisplay, setFilterToDisplay] =
    useState<TradingNewsFilterEnum>(filterFromDashboard);

  function removeFilter(filter: any) {
    if (TYPE_NEWS === filter) {
      setFilterToDisplay(null);
      setPageNumber(0);
    }
  }

  function changeFilter(filter: TradingNewsFilterEnum) {
    try {
      if (filter && filter !== filterToDisplay) {
        setFilterToDisplay(filter);
        setPageNumber(0);
      }
    } catch (e) {
      console.error("Failed to change filter ", filter, e);
    }
  }

  const onPaginationUpdate = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const client = luxseGatewayGraphqlClient;

  const fetchEquities = async (isInit: boolean) => {
    let tmpData: SecuritySearchResultDto = null;
    let tmpTotalHits = 0;

    try {
      const result: any = await client.query({
        query: SEARCH_EQUITIES_WITH_BBO,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          searchTerm: "",
          size: isInit ? 0 : pageSize,
          page: isInit ? 0 : pageNumber,
          sort: "listingDate, DESC",
        },
        fetchPolicy:"network-only",
        errorPolicy:"all"
      });

      if( result?.errors != null )
          console.error("Errors when get equities with BBO ", result?.errors);

      if (isInit)
        tmpTotalHits = result?.data?.searchEquitiesWithBBO?.totalHits || 0;
      else tmpData = result?.data?.searchEquitiesWithBBO;
    } catch (e) {
      console.log("Failed to get Equities", e);
      tmpData = null;
      tmpTotalHits = 0;
    }

    if (isInit) {
      updateFiltersNumber(TradingNewsFilterEnum.equities, tmpTotalHits);
      checkLoader();
    } else {
      setPageLoader(false);
      setResults(tmpData);
    }
  };

  const fetchFixedIncome = async (isInit: boolean) => {
    let tmpData: SecuritySearchResultDto = null;
    let tmpTotalHits = 0;

    try {
      const result: any = await client.query({
        query: SEARCH_FIXED_INCOME_WITH_BBO,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          searchTerm: "",
          size: isInit ? 0 : pageSize,
          page: isInit ? 0 : pageNumber,
          sort: "listingDate, DESC",
        },
        fetchPolicy:"network-only",
        errorPolicy:"all"
      });

      if( result?.errors != null )
          console.error("Errors when get fixed income ", result?.errors);

      if (isInit)
        tmpTotalHits = result?.data?.searchFixedIncomeWithBBO?.totalHits || 0;
      else tmpData = result?.data?.searchFixedIncomeWithBBO;
    } catch (e) {
      console.log("Failed to get Fixed income", e);
      tmpData = null;
      tmpTotalHits = 0;
    }

    if (isInit) {
      updateFiltersNumber(TradingNewsFilterEnum.fixedIncome, tmpTotalHits);
      checkLoader();
    } else {
      setPageLoader(false);
      setResults(tmpData);
    }
  };

  const fetchRenminbiBonds = async (isInit: boolean) => {
    let tmpData: SecuritySearchResultDto = null;
    let tmpTotalHits = 0;

    try {
      const result: any = await client.query({
        query: SEARCH_RENMINBI_BONDS,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          searchTerm: "",
          size: isInit ? 0 : pageSize,
          page: isInit ? 0 : pageNumber,
          sort: "listingDate, DESC",
        },
        fetchPolicy:"network-only",
        errorPolicy:"all"
      });

      if( result?.errors != null )
          console.error("Errors when get renminbi bonds ", result?.errors);

      if (isInit)
        tmpTotalHits = result?.data?.searchRenminbiBonds?.totalHits || 0;
      else tmpData = result?.data?.searchRenminbiBonds;
    } catch (e) {
      console.error("Failed to get Renminbi Bonds", e);
      tmpData = null;
      tmpTotalHits = 0;
    }

    if (isInit) {
      updateFiltersNumber(TradingNewsFilterEnum.renminbiBonds, tmpTotalHits);
      checkLoader();
    } else {
      setPageLoader(false);
      setResults(tmpData);
    }
  };

  const fetchLgxSustainableBonds = async (isInit: boolean) => {
    let tmpData: SecuritySearchResultDto = null;
    let tmpTotalHits = 0;

    try {
      const result: any = await client.query({
        query: SEARCH_LGX_SUSTAINABLE_BONDS,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          searchTerm: "",
          size: isInit ? 0 : pageSize,
          page: isInit ? 0 : pageNumber,
          sort: "listingDate, DESC",
        },
        fetchPolicy:"network-only",
        errorPolicy:"all"
      });

      if( result?.errors != null )
          console.error("Errors when get LGX sustainable bonds ", result?.errors);

      if (isInit)
        tmpTotalHits = result?.data?.searchLGXSustainableBonds?.totalHits || 0;
      else tmpData = result?.data?.searchLGXSustainableBonds;
    } catch (e) {
      console.error("Failed to get LGX Sustainable bonds", e);
      tmpData = null;
      tmpTotalHits = 0;
    }

    if (isInit) {
      updateFiltersNumber(TradingNewsFilterEnum.lgx, tmpTotalHits);
      checkLoader();
    } else {
      setPageLoader(false);
      setResults(tmpData);
    }
  };

  const fetchFunds = async (isInit: boolean) => {
    let tmpData: SecuritySearchResultDto = null;
    let tmpTotalHits = 0;
    try {
      const result: any = await client.query({
        query: SEARCH_FUNDS_WITH_BBO,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          searchTerm: "",
          size: isInit ? 0 : pageSize,
          page: isInit ? 0 : pageNumber,
          sort: "listingDate, DESC",
        },
        fetchPolicy:"network-only",
        errorPolicy:"all"
      });

      if( result?.errors != null )
          console.error("Errors when get funds with BBO ", result?.errors);

      if (isInit)
        tmpTotalHits = result?.data?.searchFundsWithBBO?.totalHits || 0;
      else tmpData = result?.data?.searchFundsWithBBO;
    } catch (e) {
      console.log("Failed to get Funds", e);
      tmpData = null;
      tmpTotalHits = 0;
    }

    if (isInit) {
      updateFiltersNumber(TradingNewsFilterEnum.funds, tmpTotalHits);
      checkLoader();
    } else {
      setPageLoader(false);
      setResults(tmpData);
    }
  };

  const loadNumbers = async () => {
    try {
      fetchLgxSustainableBonds(true);
      fetchFixedIncome(true);
      fetchRenminbiBonds(true);
      fetchEquities(true);
      fetchFunds(true);
      checkLoader();
    } catch (e) {
      console.error("Failed to load numbers", e);
      setLoader(false);
    }
  };

  const loadResults = async () => {
    try {
      setPageLoader(true);
      switch (filterToDisplay) {
        case TradingNewsFilterEnum.lgx:
          fetchLgxSustainableBonds(false);
          break;
        case TradingNewsFilterEnum.fixedIncome:
          fetchFixedIncome(false);
          break;
        case TradingNewsFilterEnum.renminbiBonds:
          fetchRenminbiBonds(false);
          break;
        case TradingNewsFilterEnum.funds:
          fetchFunds(false);
          break;
        case TradingNewsFilterEnum.equities:
        default:
          fetchEquities(false);
          break;
      }
    } catch (e) {
      console.error("Failed to load results", e);
      setResults(null);
      setPageLoader(false);
    }
  };

  useEffect(() => {
    if (loader) {
      loadNumbers();
    }
    loadResults();
  }, [filterToDisplay, pageNumber]);

  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  const switchFilter = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  return (
    <div>
      <SearchInput onSearchInputUpdate={null} routeTextInput={null} />

      <Loader loading={loader} padding={"5em !important"}>
        <div css={genericStyle.desktopDisplay}>
          <MarketNewsTagFilter
            nbResults={results?.totalHits || 0}
            filters={{ filterType: getFilterLabel(filterToDisplay) }}
            onFilterClear={null}
            onReset={null}
            handelClick={removeFilter}
            label={"Trading News"}
          />

          <div css={marketNewsStyle.tabs}>
            <div css={marketNewsStyle.tabContentWrapper}>
              <div css={marketNewsStyle.tabContainer}>
                <div css={marketNewsStyle.searchResultsFilters}>
                  <MarketTradingFilters
                    types={filters}
                    typeToDisplay={filterToDisplay}
                    handleClick={changeFilter}
                  />
                </div>
                <div css={marketNewsStyle.tabContainer}>
                  <SearchTable
                    loadingPage={pageLoader}
                    data={results?.securities || []}
                    density={null}
                    onPaginationUpdate={onPaginationUpdate}
                    pageNumber={pageNumber}
                    totalResultSize={results?.totalHits || 0}
                    Header={() => <TradingNewsHeader type={filterToDisplay} />}
                    Row={(row) => (
                      <TradingNewsRow
                        row={row}
                        type={filterToDisplay}
                        key={`result-row-${row?.id}`}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div css={genericStyle.mobileDisplay}>
          <ResultsIndicator
            category="Trading News"
            filters={{ q: filterToDisplay }}
            resultsNumber={results?.totalHits}
          />
          <div css={{ margin: "2em" }}>
            <SearchMobile
              loadingPage={pageLoader}
              onPaginationUpdate={onPaginationUpdate}
              pageNumber={pageNumber}
              data={results?.securities}
              totalResultSize={results?.totalHits}
              Card={(card) => (
                <TradingNewsCard
                  row={card}
                  type={filterToDisplay}
                  key={`result-mobile-row-${card?.id}`}
                />
              )}
            />
          </div>
          <FilterButton onSwitchFilter={switchFilter} open={menuIsOpen} />
          <MobileFilterContainer open={menuIsOpen}>
            <MarketTradingFilters
              types={filters}
              typeToDisplay={filterToDisplay}
              handleClick={changeFilter}
            />
          </MobileFilterContainer>
        </div>
      </Loader>
    </div>
  );
}

export default TradingNewsView;
