/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { sharedStyle } from "../../../../styles/shared";
import { SecuritiesBBOTable } from "./SecuritiesBBO/table";
import GenericLink from "./Shared/generic-link.component";
import { TradingNewsFilterEnum } from "../TradingNews";
import WidgetCard from "../../WidgetCard";
import PreciousMetalsTable from "./PreciousMetals/table";
import { luxseGatewayGraphqlClient } from "../../../../luxse-gateway-graphql-client";
import { GET_PRECIOUS_METALS_QUERY } from "../../../../graphql/queries/securities/precious-metal.queries";
import { PreciousMetal } from "../../../../interfaces/precious-metal";
import {
  SEARCH_EQUITIES_WITH_BBO,
  SEARCH_FIXED_INCOME_WITH_BBO,
  SEARCH_LGX_SUSTAINABLE_BONDS,
  SEARCH_RENMINBI_BONDS,
} from "../../../../graphql";
import SearchSecurity from "../../../../interfaces/search/search-security";
import RenminbiBondsTable from "./RenminbiBonds/table";
import LGXSustainableBondsTable from "./LGXSustainableBonds/table";
import Utils from "../../../utils/utils";

export class TradingDataModel<T> {
  allData: T[] = [];
  startIndex: number;
  endIndex: number;
  currentData: T[] = [];
  totalHits: number;
  loader = true;
  label = "";
}

export const TradingDataView = () => {
  const instance = axios.create();
  const client = luxseGatewayGraphqlClient;

  const [equities, setEquities] = useState<TradingDataModel<any>>();
  const [fixedIncome, setFixedIncome] = useState<TradingDataModel<any>>();
  //const [funds, setFunds] = useState<TradingDataModel<any>>();
  const [renmninbiBonds, setRenmninbiBonds] = useState<TradingDataModel<any>>();
  const [preciousMetals, setPreciousMetals] = useState<TradingDataModel<any>>();
  const [lgxSustainableBonds, setLgxSustainableBonds] =
    useState<TradingDataModel<any>>();

  const fetchEquities = async () => {
    const equitiesModel: TradingDataModel<any> = new TradingDataModel();
    equitiesModel.startIndex = 0;
    equitiesModel.endIndex = 9;
    equitiesModel.loader = true;
    equitiesModel.label = "Equities";

    try {
      const result: any = await client.query({
        query: SEARCH_EQUITIES_WITH_BBO,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          searchTerm: "",
          size: 10,
          page: 0,
          sort: "listingDate, DESC",
        },
        fetchPolicy:"network-only",
        errorPolicy:"all"
      });

      if( result?.errors != null )
          console.error("Errors when get equities with BBO ", result?.errors);

      equitiesModel.loader = false;
      equitiesModel.currentData =
        result?.data?.searchEquitiesWithBBO?.securities || [];
      equitiesModel.totalHits =
        result?.data?.searchEquitiesWithBBO?.totalHits || 0;
    } catch (e) {
      console.log("Failed to get Equities", e);
      equitiesModel.loader = false;
    }

    setEquities(equitiesModel);
  };

  const fetchFixedIncome = async () => {
    const fixedIncomeModel: TradingDataModel<any> = new TradingDataModel();
    fixedIncomeModel.startIndex = 0;
    fixedIncomeModel.endIndex = 9;
    fixedIncomeModel.loader = true;
    fixedIncomeModel.label = "Fixed Income";

    try {
      const result: any = await client.query({
        query: SEARCH_FIXED_INCOME_WITH_BBO,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          searchTerm: "",
          size: 10,
          page: 0,
          sort: "listingDate, DESC",
        },
        fetchPolicy:"network-only",
        errorPolicy:"all"
      });

      if( result?.errors != null )
          console.error("Errors when get fixed income ", result?.errors);

      fixedIncomeModel.loader = false;
      fixedIncomeModel.currentData =
        result?.data?.searchFixedIncomeWithBBO?.securities || [];
      fixedIncomeModel.totalHits =
        result?.data?.searchFixedIncomeWithBBO?.totalHits || 0;
    } catch (e) {
      console.log("Failed to get Fixed income", e);
      fixedIncomeModel.loader = false;
    }

    setFixedIncome(fixedIncomeModel);
  };

  const fetchRenminbiBonds = async () => {
    const modelTmp: TradingDataModel<SearchSecurity> = new TradingDataModel();
    modelTmp.label = "Renminbi Bonds";
    modelTmp.startIndex = 0;
    modelTmp.endIndex = 9;

    try {
      const result: any = await client.query({
        query: SEARCH_RENMINBI_BONDS,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          searchTerm: "",
          size: 10,
          page: 0,
          sort: "listingDate, DESC",
        },
        fetchPolicy:"network-only",
        errorPolicy:"all"
      });

      if( result?.errors != null )
          console.error("Errors when get renminbi bonds ", result?.errors);

      modelTmp.loader = false;
      modelTmp.currentData =
        result?.data?.searchRenminbiBonds?.securities || [];
      modelTmp.totalHits = result?.data?.searchRenminbiBonds?.totalHits || 0;
    } catch (e) {
      console.error("Failed to get Renminbi Bonds", e);
    }

    setRenmninbiBonds(modelTmp);
  };

  const fetchPreciousMetals = async () => {
    const modelTmp: TradingDataModel<PreciousMetal> = new TradingDataModel();
    modelTmp.label = "Precious Metals";
    modelTmp.startIndex = 0;
    modelTmp.endIndex = 5;

    try {
      const result: any = await client.query({
        query: GET_PRECIOUS_METALS_QUERY,
        fetchPolicy:"network-only",
        errorPolicy:"all"
      });

      if( result?.errors != null )
          console.error("Errors when get precious metals ", result?.errors);

      modelTmp.loader = false;
      modelTmp.allData = result?.data?.preciousMetals || [];
      modelTmp.totalHits = modelTmp.allData?.length;
      modelTmp.currentData = modelTmp.allData.slice(
        modelTmp.startIndex,
        modelTmp.endIndex
      );
    } catch (e) {
      console.error("Failed to get Precious metals", e);
    }

    setPreciousMetals(modelTmp);
  };

  const fetchLgxSustainableBonds = async () => {
    const modelTmp: TradingDataModel<SearchSecurity> = new TradingDataModel();
    modelTmp.label = "Sustainable bonds on LGX";
    modelTmp.startIndex = 0;
    modelTmp.endIndex = 9;

    try {
      const result: any = await client.query({
        query: SEARCH_LGX_SUSTAINABLE_BONDS,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          searchTerm: "",
          size: 10,
          page: 0,
          sort: "listingDate, DESC",
        },
        fetchPolicy:"network-only",
        errorPolicy:"all"
      });

      if( result?.errors != null )
          console.error("Errors when get LGX sustainable bonds ", result?.errors);

      modelTmp.loader = false;
      modelTmp.currentData =
        result?.data?.searchLGXSustainableBonds?.securities || [];
      modelTmp.totalHits =
        result?.data?.searchLGXSustainableBonds?.totalHits || 0;
    } catch (e) {
      console.error("Failed to get LGX Sustainable bonds", e);
    }

    setLgxSustainableBonds(modelTmp);
  };

  function buildEmptyObject() {
    const o: TradingDataModel<any> = new TradingDataModel();
    o.totalHits = 0;
    o.loader = false;
    return o;
  }

  useEffect(() => {
    fetchEquities();
    fetchFixedIncome();

    fetchRenminbiBonds();
    fetchPreciousMetals();

    fetchLgxSustainableBonds();
  }, []);

  const pathname = "/market-overview/trading-data/trading-news";
  return (
    <>
      <div css={sharedStyle.row}>
        <WidgetCard
          loader={equities ? equities.loader : true}
          icon="exchange"
          label="Equities"
          link={
            equities?.totalHits > 0 ? (
              <GenericLink
                pathname={pathname + "?type=" + TradingNewsFilterEnum.equities}
                data={TradingNewsFilterEnum.equities}
                label={equities?.label}
                totalHits={equities?.totalHits}
              />
            ) : (
              <></>
            )
          }
        >
          <SecuritiesBBOTable data={equities?.currentData} label={"equities"} />
        </WidgetCard>

        <WidgetCard
          loader={fixedIncome ? fixedIncome.loader : true}
          icon="toolbox"
          label="Fixed income"
          link={
            fixedIncome?.totalHits > 0 ? (
              <GenericLink
                pathname={
                  pathname + "?type=" + TradingNewsFilterEnum.fixedIncome
                }
                data={TradingNewsFilterEnum.fixedIncome}
                label={fixedIncome?.label}
                totalHits={fixedIncome?.totalHits}
              />
            ) : (
              <></>
            )
          }
        >
          <SecuritiesBBOTable
            data={fixedIncome?.currentData}
            label={"fixed-income"}
          />
        </WidgetCard>
      </div>
      <div css={sharedStyle.row}>
        <WidgetCard
          loader={!lgxSustainableBonds || lgxSustainableBonds?.loader}
          icon="leaf"
          label="Sustainable bonds displayed on LGX"
          link={
            <GenericLink
              pathname={pathname + "?type=" + TradingNewsFilterEnum.lgx}
              data={TradingNewsFilterEnum.lgx}
              label={lgxSustainableBonds?.label}
              totalHits={null}
            />
          }
        >
          <LGXSustainableBondsTable data={lgxSustainableBonds?.currentData} />
        </WidgetCard>

        <WidgetCard
          loader={!renmninbiBonds || renmninbiBonds?.loader}
          icon="file-certificate"
          label="Renminbi bonds"
          link={
            <GenericLink
              pathname={
                pathname + "?type=" + TradingNewsFilterEnum.renminbiBonds
              }
              data={TradingNewsFilterEnum.renminbiBonds}
              label={renmninbiBonds?.label}
              totalHits={renmninbiBonds?.totalHits}
            />
          }
        >
          <RenminbiBondsTable data={renmninbiBonds?.currentData} />
        </WidgetCard>

        <WidgetCard
          loader={!preciousMetals || preciousMetals?.loader}
          icon="medal"
          label="Precious metals"
          link={
            <GenericLink
              pathname={"/market-overview/precious-metals"}
              data={null}
              label={preciousMetals?.label}
              totalHits={preciousMetals?.totalHits}
            />
          }
        >
          <PreciousMetalsTable data={preciousMetals?.currentData} />
        </WidgetCard>
      </div>
    </>
  );
};

export const DataMarket = withRouter(TradingDataView);
