import * as React from "react";

import {
    GET_LATEST_LISTINGS,
    GET_LATEST_SUSPENSIONS,
    GET_SEARCH_ISSUERS_ONLY_RESULTS, GET_SECURITIES_MARKET_TRANSFER,
    SEARCH_INDEXES_PRICE_WITHOUT_FILTERS,
    GET_LATEST_FNS,
    SEARCH_LGX_SUSTAINABLE_BONDS,
    GET_LATEST_CSSF_APPROVAL,
    GET_UPCOMING_DELISTINGS,
    GET_LATEST_ADMISSION_TO_TRADING,
    GET_UPCOMING_REMOVAL_FROM_TRADING
} from "../../../../../graphql";
import {css, jsx} from "@emotion/react";
import {useEffect, useState} from "react";

import {BACKBOURSE_ENDPOINT} from "../../../../../backbourse";
import CssfApprovalsView from "./CSSFApprovals/cssf-approvals-view.component";
import GenericLink from "../../TradingData/Shared/generic-link.component";
import IndexTradingView from "./Indices/index-market-data-view.component";
import {LgxSustainableBondsView} from "./LgxSustainableBonds/index";
import LatestFnsView from "./LatestFNS/latest-fns-view.component";
import LatestSuspensionsView from "./LatestSuspensions/latest-suspensions-view.component";
import Loader from "../../../Loader/loader.component";
import LuxXPrimeView from "./LuxXPrime/luxx-prime-view.component";
import {MarketNewsFilterEnum} from "../../MarketNews";
import MarketTransfersView from "./MarketTransfers/market-transfers-view.component";
import {MarketdataModel} from "../market-data-view.component";
import NewIssuerView from "./NewIssuer/new-issuer-view.component";
import NewListingsView from "./NewListings/new-listings-view.component";
import UpcomingDelistingView from "./UpcomingDelisting/upcoming-delisting-view.component";
import WidgetCard from "../../../WidgetCard";
import axios from "axios";
import lgxlogo from "./LGX.svg";
import {luxseGatewayGraphqlClient} from "../../../../../luxse-gateway-graphql-client";
import luxxprimeLogo from "./luxXPrime.svg";
import {renderLink} from "../../../../../utils/helper";
import {sharedStyle} from "../../../../../styles/shared";
import {tradingStyle} from "../trading-data.style";
import {GET_LUXXPRIME_LAST_TRADED_QUERY} from "../../../../../graphql/queries/luxxprime.queries";
import {indexDetailPageLink} from "../../../Index/Index-details";
import Utils from "../../../../utils/utils";

/** @jsx jsx */

export const AllSecurities = (props: any) => {
    const instance = axios.create();
    const client = luxseGatewayGraphqlClient;
    const pathname = "/market-overview/market-news";

    const [fns, setFns] = useState<MarketdataModel>();
    const [latestListings, setLatestListings] = useState<MarketdataModel>();
    const [index, setIndex] = useState<MarketdataModel>();
    const [cssfApprovals, setCssfApprovals] = useState<MarketdataModel>();
    const [newIssuers, setNewIssuers] = useState<MarketdataModel>();
    const [marketTransfer, setMarketTransfer] = useState<MarketdataModel>();
    const [suspensions, setSuspensions] = useState<MarketdataModel>();
    const [upcomingDelisting, setUpcomingDelisting] = useState<MarketdataModel>();
    const [latestAdmissionsToTrading, setLatestAdmissionToTrading] = useState<MarketdataModel>();
    const [upcomingRemovalFromTrading, setUpcomingRemovalFromTrading] = useState<MarketdataModel>();

    const [lgxSustainableBonds, setLgxSustainableBonds] =
        useState<MarketdataModel>();
    const [luxXPrime, setLuxXPrime] = useState<MarketdataModel>();

    const fetchLatestFNS = async () => {
        const fnsModel: MarketdataModel = new MarketdataModel();
        fnsModel.startIndex = 0;
        fnsModel.endIndex = 9;
        fnsModel.loader = true;
        fnsModel.label = "Latest FNS";

        try {
            const results = await client.query({
                query: GET_LATEST_FNS,
                variables: {
                    pageNumber: 0,
                    pageSize: 0,
                },
                fetchPolicy:"network-only",
                errorPolicy:"all"
            });

            fnsModel.loader = false;

            if( results?.errors != null )
                console.error("Errors when get latest FNS ", results?.errors);

            if (results?.data?.latestFNS) {
                fnsModel.allData = results.data.latestFNS;
                fnsModel.currentData = fnsModel.allData.slice(
                    fnsModel.startIndex,
                    fnsModel.endIndex
                );
                fnsModel.totalHits = results.data.latestFNS.length;
            }
        } catch (e) {
            console.error("Failed to get Latest FNS", e);
            fnsModel.loader = false;
        }

        setFns(fnsModel);
    };


    const fetchNewListings = async () => {
        const latestListingsModel: MarketdataModel = new MarketdataModel();
        latestListingsModel.loader = true;
        latestListingsModel.label = "New listings";
        latestListingsModel.startIndex = 0;
        latestListingsModel.endIndex = 9;

        try {
            const results = await client.query({
                query: GET_LATEST_LISTINGS,
                variables: {
                    pageNumber: 0,
                    pageSize: 0,
                },
                fetchPolicy:"network-only",
                errorPolicy:"all"
            });

            latestListingsModel.loader = false;

            if( results?.errors != null )
                console.error("Errors when get new listings ", results?.errors);

            if (results?.data?.securitiesLatestListing) {
                latestListingsModel.allData = results.data.securitiesLatestListing;
                latestListingsModel.totalHits = results.data.securitiesLatestListing.length;
                latestListingsModel.currentData = results.data.securitiesLatestListing.slice(
                    latestListingsModel.startIndex,
                    latestListingsModel.endIndex
                );
            }
        } catch (e) {
            console.error("Failed to get New listings", e);
            latestListingsModel.loader = false;
        }

        setLatestListings(latestListingsModel);
    };

    const fetchIndex = async () => {
        const indexModel: MarketdataModel = new MarketdataModel();
        indexModel.label = "Indices";
        indexModel.loader = true;

        try {
            const result: any = await client.query({
                query: SEARCH_INDEXES_PRICE_WITHOUT_FILTERS,
                variables: {
                    searchTerm: "",
                    size: 5,
                    page: 0,
                    sort: "latestPriceDate,asc",
                    types: ["IALU", "IOPC"],
                    typesOperator: "OR",
                },
                fetchPolicy:"network-only",
                errorPolicy:"all"
            });

            if( result?.errors != null )
                console.error("Errors when get indices ", result?.errors);

            if (result.data?.luxseIndexesSearch?.indexes) {
                indexModel.allData = result.data?.luxseIndexesSearch?.indexes;
                indexModel.totalHits = result.data?.luxseIndexesSearch?.totalHits;
            }

            indexModel.loader = false;
        } catch (e) {
            console.error("Failed to Get indices", e);
            indexModel.loader = false;
        }

        setIndex(indexModel);
    };

    const fetchCSSFApprovals = async () => {
        const cssfApprovalsModel: MarketdataModel = new MarketdataModel();
        cssfApprovalsModel.loader = true;
        cssfApprovalsModel.label = "CSSF Approvals";
        cssfApprovalsModel.startIndex = 0;
        cssfApprovalsModel.endIndex = 9;

        try {
            const results = await client.query({
                query: GET_LATEST_CSSF_APPROVAL,
                variables: {
                    pageNumber: 0,
                    pageSize: 0,
                },
                fetchPolicy:"network-only",
                errorPolicy:"all"
            });

            cssfApprovalsModel.loader = false;

            if( results?.errors != null )
                console.error("Errors when get CSSF approvals ", results?.errors);

            if (results?.data?.latestCssfApproval) {
                cssfApprovalsModel.allData = results.data.latestCssfApproval;
                cssfApprovalsModel.totalHits = results.data.latestCssfApproval.length;
                cssfApprovalsModel.currentData = results.data.latestCssfApproval.slice(
                    cssfApprovalsModel.startIndex,
                    cssfApprovalsModel.endIndex
                );
            }
        } catch (e) {
            console.error("Failed to get CSSF Approvals", e);
            cssfApprovalsModel.loader = false;
        }

        setCssfApprovals(cssfApprovalsModel);
    };


    const fetchMarketTransfers = async () => {
        const marketTransfersModel = new MarketdataModel();
        marketTransfersModel.startIndex = 0;
        marketTransfersModel.endIndex = 9;
        marketTransfersModel.loader = true;
        marketTransfersModel.label = "Market Transfers";

        try {
            const results = await client.query({
                query: GET_SECURITIES_MARKET_TRANSFER,
                variables: {
                    token: Utils.getCookie("Gztoken-Dsm"),
                    pageNumber: 0,
                    pageSize: 0,
                },
                fetchPolicy:"network-only",
                errorPolicy:"all"
            });

            marketTransfersModel.loader = false;

            if( results?.errors != null )
                console.error("Errors when get market transfers ", results?.errors);

            if (results?.data?.securitiesLatestMarketTransfer) {
                marketTransfersModel.allData = results.data.securitiesLatestMarketTransfer;
                marketTransfersModel.totalHits = results.data.securitiesLatestMarketTransfer.length;
                marketTransfersModel.currentData = results.data.securitiesLatestMarketTransfer.slice(
                    marketTransfersModel.startIndex,
                    marketTransfersModel.endIndex
                );
            }
        } catch (e) {
            console.error("Failed to get market transfer", e);
            marketTransfersModel.loader = false;
        }

        setMarketTransfer(marketTransfersModel);
    };


    const fetchSuspensions = async () => {
        const suspensionsModel: MarketdataModel = new MarketdataModel();
        suspensionsModel.label = "Latest Suspensions";
        suspensionsModel.startIndex = 0;
        suspensionsModel.endIndex = 9;
        suspensionsModel.loader = true;


        try {
            const results = await client.query({
                query: GET_LATEST_SUSPENSIONS,
                variables: {
                    pageNumber: 0,
                    pageSize: 0,
                },
                fetchPolicy:"network-only",
                errorPolicy:"all"
            })

          suspensionsModel.loader = false;

          if( results?.errors != null )
              console.error("Errors when get latest suspensions ", results?.errors);

          const reason = "ARET";
          suspensionsModel.allData = results.data.securitiesLatestSuspension.sort((s1: any, s2: any) => {
            if (s1.suspensionReason != reason && s2.suspensionReason != reason)
              return s1.suspensionDate - s2.suspensionDate;
            else if (s1.suspensionReason != reason) return -1;
            else if (s2.suspensionReason != reason) return 1;
            else return s1.suspensionDate - s2.suspensionDate;
          });

          suspensionsModel.totalHits = results.data.securitiesLatestSuspension.length;

          suspensionsModel.currentData = results.data.securitiesLatestSuspension.slice(
              suspensionsModel.startIndex,
              suspensionsModel.endIndex
          );

        } catch (e) {
            console.error("Failed to get suspensions", e);
            suspensionsModel.loader = false;
        }

        setSuspensions(suspensionsModel);
    };


    const fetchUpcomingDelistings = async () => {
        const upcomingDelistingsModel: MarketdataModel = new MarketdataModel();
        upcomingDelistingsModel.startIndex = 0;
        upcomingDelistingsModel.endIndex = 9;
        upcomingDelistingsModel.label = "Upcoming Delistings";
        upcomingDelistingsModel.loader = true;

        try {
            const results = await client.query({
                query: GET_UPCOMING_DELISTINGS,
                variables: {
                    pageNumber: 0,
                    pageSize: 0,
                },
                fetchPolicy:"network-only",
                errorPolicy:"all"
            });

            upcomingDelistingsModel.loader = false;

            if( results?.errors != null )
                console.error("Errors when get upcoming delistings ", results?.errors);

            if (results?.data?.securitiesUpcomingDelistings) {
                upcomingDelistingsModel.allData = results.data.securitiesUpcomingDelistings;
                upcomingDelistingsModel.totalHits = results.data.securitiesUpcomingDelistings.length;
                upcomingDelistingsModel.currentData = results.data.securitiesUpcomingDelistings.slice(
                    upcomingDelistingsModel.startIndex,
                    upcomingDelistingsModel.endIndex
                );
            }
        } catch (e) {
            console.error("Failed to get upcoming delistings", e);
            upcomingDelistingsModel.loader = false;
        }

        setUpcomingDelisting(upcomingDelistingsModel);
    };

    const fetchLatestAdmissionToTrading = async () => {
        const latestAdmissionToTradingModel: MarketdataModel = new MarketdataModel();
        latestAdmissionToTradingModel.startIndex = 0;
        latestAdmissionToTradingModel.endIndex = 9;
        latestAdmissionToTradingModel.label = "New tradable securities";
        latestAdmissionToTradingModel.loader = true;

        try {
            const results = await client.query({
                query: GET_LATEST_ADMISSION_TO_TRADING,
                variables: {
                    pageNumber: 0,
                    pageSize: 0,
                },
                fetchPolicy:"network-only",
                errorPolicy:"all"
            });

            latestAdmissionToTradingModel.loader = false;

            if( results?.errors != null )
                console.error("Errors when get new tradable securities ", results?.errors);

            if (results?.data?.securitiesLatestAdmissionToTrading) {
                latestAdmissionToTradingModel.allData = results.data.securitiesLatestAdmissionToTrading;
                latestAdmissionToTradingModel.totalHits = results.data.securitiesLatestAdmissionToTrading.length;
                latestAdmissionToTradingModel.currentData = results.data.securitiesLatestAdmissionToTrading.slice(
                    latestAdmissionToTradingModel.startIndex,
                    latestAdmissionToTradingModel.endIndex
                );
            }
        } catch (e) {
            console.error("Failed to get new tradable securities", e);
            latestAdmissionToTradingModel.loader = false;
        }

        setLatestAdmissionToTrading(latestAdmissionToTradingModel);
    };

    const fetchUpcomingRemovalFromTrading = async () => {
        const upcomingRemovalFromTradingModel: MarketdataModel = new MarketdataModel();
        upcomingRemovalFromTradingModel.startIndex = 0;
        upcomingRemovalFromTradingModel.endIndex = 9;
        upcomingRemovalFromTradingModel.label = "Upcoming removals from trading";
        upcomingRemovalFromTradingModel.loader = true;

        try {
            const results = await client.query({
                query: GET_UPCOMING_REMOVAL_FROM_TRADING,
                variables: {
                    pageNumber: 0,
                    pageSize: 0,
                },
                fetchPolicy:"network-only",
                errorPolicy:"all"
            });

            upcomingRemovalFromTradingModel.loader = false;

            if( results?.errors != null )
                console.error("Errors when get upcoming removals from trading ", results?.errors);

            if (results?.data?.securitiesUpcomingRemovalFromTrading) {
                upcomingRemovalFromTradingModel.allData = results.data.securitiesUpcomingRemovalFromTrading;
                upcomingRemovalFromTradingModel.totalHits = results.data.securitiesUpcomingRemovalFromTrading.length;
                upcomingRemovalFromTradingModel.currentData = results.data.securitiesUpcomingRemovalFromTrading.slice(
                    upcomingRemovalFromTradingModel.startIndex,
                    upcomingRemovalFromTradingModel.endIndex
                );
            }
        } catch (e) {
            console.error("Failed to get upcoming removals from trading", e);
            upcomingRemovalFromTradingModel.loader = false;
        }

        setUpcomingRemovalFromTrading(upcomingRemovalFromTradingModel);
    };


    const fetchLgxSustainableBonds = async () => {
        const lgxSustainableBondsModel: MarketdataModel = new MarketdataModel();
        lgxSustainableBondsModel.loader = true;
        lgxSustainableBondsModel.label = "LGX securities";
        lgxSustainableBondsModel.startIndex = 0;
        lgxSustainableBondsModel.endIndex = 9;

        try {
            const results: any = await client.query({
                query: SEARCH_LGX_SUSTAINABLE_BONDS,
                variables: {
                    token: Utils.getCookie("Gztoken-Dsm"),
                    page: 0,
                    size: 10,
                    sort: "listingDate,desc",
                    searchTerm: "",
                },
                fetchPolicy:"network-only",
                errorPolicy:"all"
            });

            if( results?.errors != null )
                console.error("Errors when get LGX securities ", results?.errors);

            if (results?.data?.searchLGXSustainableBonds) {
                lgxSustainableBondsModel.allData =
                    results.data.searchLGXSustainableBonds.securities;
                lgxSustainableBondsModel.currentData = lgxSustainableBondsModel.allData;
                lgxSustainableBondsModel.totalHits =
                    results.data.searchLGXSustainableBonds.totalHits;
            }
            lgxSustainableBondsModel.loader = false;
        } catch (e) {
            console.error("Failed to get LGX Sustainable Bonds", e);
            lgxSustainableBondsModel.loader = false;
        }

        setLgxSustainableBonds(lgxSustainableBondsModel);
    };

    const fetchLuxXPrimeData = async () => {
        const luxXPrimeModel: MarketdataModel = new MarketdataModel();

        try {
            const results: any = await client.query({
                query: GET_LUXXPRIME_LAST_TRADED_QUERY,
                variables: {
                    token: Utils.getCookie("Gztoken-Dsm"),
                    limit: 10,
                },
                fetchPolicy:"network-only",
                errorPolicy:"all"
            });

            luxXPrimeModel.totalHits = 0;
            luxXPrimeModel.loader = false;

            if( results?.errors != null )
                console.error("Errors when get LuxXPrime securities ", results?.errors);

            if (results?.data?.lastTradedLuxXPrimeSecurities?.length > 0) {
                const data = results.data.lastTradedLuxXPrimeSecurities;

                luxXPrimeModel.allData = data;
                luxXPrimeModel.totalHits = data.length;
                luxXPrimeModel.startIndex = 0;
                luxXPrimeModel.endIndex = 9;
                luxXPrimeModel.currentData = data;
                luxXPrimeModel.loader = false;
                luxXPrimeModel.label = "LuxXPrime securities";
            }
        } catch (e) {
            console.error("Failed to get LuxXPrime data", e);
            luxXPrimeModel.totalHits = 0;
            luxXPrimeModel.loader = false;
        }

        setLuxXPrime(luxXPrimeModel);
    };

    const fetchNewIssuers = async () => {
        const newIssuersModel: MarketdataModel = new MarketdataModel();
        newIssuersModel.loader = true;
        newIssuersModel.label = "New Issuers";
        newIssuersModel.startIndex = 0;
        newIssuersModel.endIndex = 9;

        try {
            const results: any = await client.query({
                query: GET_SEARCH_ISSUERS_ONLY_RESULTS,
                variables: {
                    token: Utils.getCookie("Gztoken-Dsm"),
                    page: 0,
                    size: 10,
                    sort: "firstListingDate,desc",
                    searchTerm: "",
                },
                fetchPolicy:"network-only",
                errorPolicy:"all"
            });

            newIssuersModel.loader = false;

            if( results?.errors != null )
                console.error("Errors when get new issuers ", results?.errors);

            if (results?.data?.luxseIssuersSearch?.issuers.length > 0) {
                const data = results.data.luxseIssuersSearch?.issuers;
                newIssuersModel.allData = data;
                newIssuersModel.totalHits = data.length;
            }
        } catch (e) {
            console.error("Failed to get new Issuers data", e);
            newIssuersModel.loader = false;
        }

        setNewIssuers(newIssuersModel);
    };

    useEffect(() => {
        fetchSuspensions();
        fetchLuxXPrimeData();
        fetchNewIssuers();
        fetchLatestFNS();
        fetchNewListings();
        fetchIndex();
        fetchCSSFApprovals();
        fetchMarketTransfers();
        fetchUpcomingDelistings();
        fetchLatestAdmissionToTrading();
        fetchUpcomingRemovalFromTrading();
        fetchLgxSustainableBonds();
    }, []);

    return (
        <div css={tradingStyle.mainContent}>
            <div
                css={[
                    sharedStyle.row,
                    css`
                      table {
                        border-collapse: collapse !important;

                        tr {
                          th {
                            font-size: 0.8rem;
                          }
                        }

                        tr {
                          min-height: 64px;

                          td {
                            border-bottom: solid 1px #d9dee2;
                            height: 64px;
                            font-size: 0.8rem;
                          }
                        }
                      }
                    `,
                ]}
            >
                <WidgetCard
                    loader={lgxSustainableBonds ? lgxSustainableBonds.loader : true}
                    logo={<img src={renderLink(lgxlogo)} alt={"image"}/>}
                    link={
                        lgxSustainableBonds?.totalHits > 5 ? (
                            <GenericLink
                                pathname={"/search?lgxOnly=true"}
                                data={null}
                                label={"LGX securities"}
                                totalHits={null}
                            />
                        ) : (
                            <></>
                        )
                    }
                >
                    {lgxSustainableBonds?.totalHits > 0 ? (
                        <LgxSustainableBondsView data={lgxSustainableBonds}/>
                    ) : (
                        <div css={tradingStyle.emptyResult}>No data available</div>
                    )}
                </WidgetCard>

                <WidgetCard
                    loader={luxXPrime ? luxXPrime.loader : true}
                    logo={
                        <div
                            css={css`
                              img {
                                width: 100%;
                                max-width: 140px;
                              }
                            `}
                        >
                            <img src={renderLink(luxxprimeLogo)} alt={"image"}/>
                        </div>
                    }
                    link={
                        luxXPrime?.totalHits > 0 ? (
                            <GenericLink
                                pathname={"/search?dataType=luxXPrime"}
                                data={null}
                                label={luxXPrime?.label}
                                totalHits={null}
                            />
                        ) : (
                            <></>
                        )
                    }
                >
                    <div css={tradingStyle.cardContainerBodyFull}>
                        {luxXPrime?.totalHits > 0 ? (
                            <LuxXPrimeView luxXPrimeData={luxXPrime}/>
                        ) : (
                            <div css={tradingStyle.emptyResult}>No LuxXPrimeData found</div>
                        )}
                    </div>
                </WidgetCard>
            </div>

            <div css={sharedStyle.row}>
                <WidgetCard
                    loader={fns ? fns.loader : true}
                    icon="exchange"
                    label="Latest FNS"
                    link={
                        fns?.totalHits > 0 ? (
                            <GenericLink
                                pathname={`${pathname}?type=${MarketNewsFilterEnum.fns}`}
                                data={MarketNewsFilterEnum.fns}
                                label={fns?.label}
                                totalHits={fns?.totalHits}
                            />
                        ) : (
                            <></>
                        )
                    }
                >
                    {fns?.totalHits > 0 ? (
                        <LatestFnsView fnsData={fns}/>
                    ) : (
                        <div css={tradingStyle.emptyResult}>No data available</div>
                    )}
                </WidgetCard>
                <WidgetCard
                    loader={latestListings ? latestListings?.loader : true}
                    icon="play"
                    label="New Listings"
                    link={
                        latestListings?.totalHits > 5 ? (
                            <GenericLink
                                pathname={`${pathname}?type=${MarketNewsFilterEnum.newListing}`}
                                data={MarketNewsFilterEnum.newListing}
                                label={latestListings?.label}
                                totalHits={latestListings?.totalHits}
                            />
                        ) : (
                            <></>
                        )
                    }
                >
                    {latestListings?.totalHits > 0 ? (
                        <NewListingsView newListingsData={latestListings}/>
                    ) : (
                        <div css={tradingStyle.emptyResult}>No data available</div>
                    )}
                </WidgetCard>
                <WidgetCard
                    loader={index ? index.loader : true}
                    icon="chart-line"
                    label="Indices"
                    link={
                        <GenericLink
                            pathname={indexDetailPageLink("")}
                            data={null}
                            label={index?.label}
                            totalHits={index?.totalHits}
                        />
                    }
                >
                    <IndexTradingView indexes={index?.allData}/>
                </WidgetCard>
            </div>

            <div css={sharedStyle.row}>
                <WidgetCard
                    loader={cssfApprovals ? cssfApprovals.loader : true}
                    icon="scale-balanced"
                    iconType={"fas"}
                    label="CSSF Approvals"
                    link={
                        cssfApprovals?.totalHits > 5 ? (
                            <GenericLink
                                pathname={`${pathname}?type=${MarketNewsFilterEnum.cssf}`}
                                data={MarketNewsFilterEnum.cssf}
                                label={cssfApprovals?.label}
                                totalHits={cssfApprovals?.totalHits}
                            />
                        ) : (
                            <></>
                        )
                    }
                >
                    {cssfApprovals?.totalHits > 0 ? (
                        <CssfApprovalsView cssfApprovalsData={cssfApprovals}/>
                    ) : (
                        <div css={tradingStyle.emptyResult}>No data available</div>
                    )}
                </WidgetCard>
                <WidgetCard
                    loader={false}
                    icon="building"
                    label="New Issuers"
                    link={
                        newIssuers?.totalHits > 0 ? (
                            <GenericLink
                                pathname={"/search?dataType=issuers"}
                                data={null}
                                label={newIssuers?.label}
                                totalHits={null}
                            />
                        ) : (
                            <></>
                        )
                    }
                >
                    <Loader centered loading={newIssuers?.loader}>
                        <div css={tradingStyle.cardContainerBodyFull}>
                            {newIssuers ? (
                                <NewIssuerView data={newIssuers}/>
                            ) : (
                                <div css={tradingStyle.emptyResult}>No new issuers found</div>
                            )}
                        </div>
                    </Loader>
                </WidgetCard>
                <WidgetCard
                    loader={marketTransfer ? marketTransfer.loader : true}
                    icon="exchange"
                    label="Market transfers"
                    link={
                        marketTransfer?.totalHits > 5 ? (
                            <GenericLink
                                pathname={`${pathname}?type=${MarketNewsFilterEnum.transfers}`}
                                data={MarketNewsFilterEnum.transfers}
                                label={marketTransfer?.label}
                                totalHits={marketTransfer?.totalHits}
                            />
                        ) : (
                            <></>
                        )
                    }
                >
                    {marketTransfer?.totalHits > 0 ? (
                        <MarketTransfersView marketTransfersData={marketTransfer}/>
                    ) : (
                        <div css={tradingStyle.emptyResult}>No data available</div>
                    )}
                </WidgetCard>
            </div>

            <div css={sharedStyle.row}>
                <WidgetCard
                    loader={(suspensions ? suspensions.loader : true)}
                    icon="pause"
                    label="Latest Suspensions"
                    link={
                        suspensions?.totalHits > 5 ? (
                            <GenericLink
                                pathname={`${pathname}?type=${MarketNewsFilterEnum.suspensions}`}
                                data={MarketNewsFilterEnum.suspensions}
                                label={suspensions?.label}
                                totalHits={suspensions?.totalHits}
                            />
                        ) : (
                            <></>
                        )
                    }
                >
                    {suspensions?.totalHits > 0 ? (
                        <LatestSuspensionsView suspensionsData={suspensions}/>
                    ) : (
                        <div css={tradingStyle.emptyResult}>No data available</div>
                    )}
                </WidgetCard>
                <WidgetCard
                    loader={upcomingDelisting ? upcomingDelisting.loader : true}
                    icon="times-octagon"
                    iconType={"far"}
                    label="Upcoming delistings"
                    link={
                        upcomingDelisting?.totalHits > 5 ? (
                            <GenericLink
                                pathname={`${pathname}?type=${MarketNewsFilterEnum.upcomingDelisting}`}
                                data={MarketNewsFilterEnum.upcomingDelisting}
                                label={upcomingDelisting?.label}
                                totalHits={upcomingDelisting?.totalHits}
                            />
                        ) : (
                            <></>
                        )
                    }
                >
                    {upcomingDelisting?.totalHits > 0 ? (
                        <UpcomingDelistingView upcomingDelistingsData={upcomingDelisting}/>
                    ) : (
                        <div css={tradingStyle.emptyResult}>No data available</div>
                    )}
                </WidgetCard>
            </div>
        </div>
    );
};


export default AllSecurities;
