/* eslint-disable */
import React, {useEffect, useState} from "react";
import {css, jsx} from "@emotion/react";

const moment = require('moment');
import {BACKBOURSE_ENDPOINT} from "../../../../backbourse";
import {CalendarFilterComponent} from "./Filter/calendar-filter.component";
import FilterButton from "../../Search/results/searchMobile/filterButton";
import Loader from "../../Loader/loader.component";
import MarketNewsMenuRigthFilters from "./Filter/market-news-menu-rigth-filter.component";
import MarketNewsRow from "./row";
import {MarketNewsTagFilter} from "./Filter/market-news-tag-filter.component";
import MobileFilterContainer from "../../Search/results/searchMobile/mobileFilterContainer";
import {SearchInput} from "../../Search/input";
import {SearchMobile} from "../../UI/list/base/index-mobile";
import {SearchTable} from "../../UI/list/base";
import axios from "axios";
import {genericStyle} from "../../UI/generic/generic.style";
import {getNewListingStatusLabel} from "../../../../sitecore-components/HomeDataComponent";
import {marketNewsStyle} from "./index.style";
import ResultsIndicator from "../../Search/results/searchMobile/resultsIndicator";
import {canUseDOM} from "../../../../predicates";
import {goToNotFoundPage} from "../../404";
import {DocumentDateEnum, findDocumentDateByName} from "../../../../enums/document-date";
import {marketTransfersLabel} from "../../../../enums/market-transfers";
import {getCurrentMoment, parseToMoment} from "../../../../utils/date";
import {Thead} from "@bdl-cmn-shared-packages-npm/design-system";
import {
    GET_LATEST_LISTINGS,
    GET_LATEST_SUSPENSIONS,
    GET_SECURITIES_MARKET_TRANSFER,
    GET_LATEST_CSSF_APPROVAL,
    GET_LATEST_FNS,
    GET_UPCOMING_DELISTINGS,
    GET_LATEST_ADMISSION_TO_TRADING,
    GET_UPCOMING_REMOVAL_FROM_TRADING,
    setSuspensionReason
} from "../../../../graphql";
import {luxseGatewayGraphqlClient} from "../../../../luxse-gateway-graphql-client";
import Utils from "../../../utils/utils";
import {securityDelistingReasonLabel} from "../../../../enums/security-delistingReason";

/** @jsx jsx */

export class MarketNewsModel {
    cssfApprovals?: any;
    latestListings?: any;
    marketTransfer?: any;
    suspensions?: any;
    upcomingDelisting?: any;
    fns?: any;
    latestAdmissionToTrading?: any;
    upcomingRemovalFromTrading?: any;
}

export class MarketNewsDetailsModel {
    id?: string;
    status?: string;
    statusLabel?: string;
    type?: MarketNewsFilterEnum;
    isinCode?: string;
    currency?: string;
    date?: any;
    amount?: number;
    name?: string;
    label?: string;
    properties?: any;
    tradingCode?: string;
    delistingDate?: any;
}

export class FilterModel {
    type: string;
    typeName: MarketNewsFilterEnum;
    name?: string;
    totalHits?: number;
}

export class DateFilterModel {
    typeName: DateFilterEnum;
    name?: string;
    totalHits?: number;
}

export class TypeFilterModel {
    typeName: typeFilterEnum;
    name?: string;
    totalHits?: number;
}

// fixme backend for quotationDateChanges tab
export function computeDateValue(x: any): any {
    let value = x?.quotationDate;
    if (!x?.quotationDate) {
        value = x?.quotationDateChanges[1];
    }
    return value;
}


export enum MarketNewsFilterEnum {
    newListing = "newListing",
    suspensions = "suspensions",
    transfers = "transfers",
    cssf = "cssf",
    fns = "fns",
    upcomingDelisting = "upcomingDelisting",
    newAdmissionToTrading = "newAdmissionToTrading",
    upcomingRemovalFromTrading = "upcomingRemovalFromTrading",
}

export enum DateFilterEnum {
    ONE_DAY_AGO,
    ONE_WEEK_AGO,
    TWO_WEEKS_AGO,
    MORE_THAN_TWO_WEEKS
}

export enum typeFilterEnum {
    newListing = "newListing",
    suspensions = "suspensions",
    transfers = "transfers",
    cssf = "cssf",
    fns = "fns",
    upcomingDelisting = "upcomingDelisting",
    newAdmissionToTrading = "newAdmissionToTrading",
    upcomingRemovalFromTrading = "upcomingRemovalFromTrading",
}

export const TYPE_DATE = "typeDate";

export const TYPE_NEWS = "typeNews";

export function MarketNewsView(props: any) {
    const client = luxseGatewayGraphqlClient;

    let filterFromDashboard = props?.location?.state?.marketNewsFilter;
    if (canUseDOM) {
        const search = new URLSearchParams(window?.location?.search);
        const searchType = search?.get("type");
        if (searchType && (searchType)) {
            filterFromDashboard = searchType;
        }
    }

    const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
    const marketNewsFilterDraft: FilterModel[] = [];

    const [marketNewsAllData, setMarketNewsAllData] = useState<MarketNewsDetailsModel[]>(null);
    const [marketNewsFilteredData, setMarketNewsFilteredData] = useState<MarketNewsDetailsModel[]>(null);
    const [marketNewsFilter, setMarketNewsFilter] = useState<FilterModel[]>([]);
    // Filter to display on header search
    const [typeFilter, setTypeFilter] = useState<FilterModel>();
    const [dateFilter, setDateFilter] = useState<string>("");

    const onPaginationUpdate = (pageNumber: number) => {
        setCurrentPageNumber(pageNumber);
    };

    function createPage(dataMarketModel: any): any {
        let startIndex = 0;
        let endIndex = 20;
        if (currentPageNumber > 0) {
            endIndex = (currentPageNumber + 1) * 20;
            startIndex = endIndex - 20;
        }
        return dataMarketModel.slice(startIndex, endIndex);
    }

    function changeTypeFilter(filter: FilterModel, allData: MarketNewsDetailsModel[]) {
        setDateFilter(null);
        setTypeFilter(filter);
        // initialize page number to avoid no result bug
        setCurrentPageNumber(0);
        let result = filterDataByType(filter, allData);
        setMarketNewsFilteredData(result);
    }

    function changeDateFilter(filter: any) {
        let result;
        setDateFilter(filter);
        if (typeFilter) {
            result = filterDataByType(typeFilter, null);
        }
        result = filterDataByDate(findDocumentDateByName(filter), result);
        setMarketNewsFilteredData(result);
        // initialize page number to avoid no result bug
        setCurrentPageNumber(0);
    }

    function removeFilter(filter: any) {
        if (TYPE_NEWS === filter) {
            setTypeFilter(null);
            setMarketNewsFilteredData(marketNewsAllData);
        } else {
            if (typeFilter) {
                setMarketNewsFilteredData(filterDataByType(typeFilter, null));
            }
            setDateFilter(null);
        }
    }

    function filterDataByDate(filter: DocumentDateEnum, allData: MarketNewsDetailsModel[]): any[] {
        let data: MarketNewsDetailsModel[] = [];
        const now = getCurrentMoment();
        const towWeeks: any = now.subtract(14, "days");
        const oneWeek: any = now.subtract(7, "days");
        const oneDay: any = now.subtract(1, "day");

        data = !allData && marketNewsAllData?.length > 0 ? marketNewsAllData : allData;

        switch (filter) {
            case DocumentDateEnum.TWO_WEEKS_AGO:
                return data?.filter((x) => {
                    return parseToMoment(x.date).isBetween(towWeeks, now);
                });
            case DocumentDateEnum.ONE_WEEK_AGO:
                return data?.filter((x) => {
                    return x.date <= now && x.date >= oneWeek;
                });
            case DocumentDateEnum.ONE_DAY_AGO:
                return data?.filter((x) => {
                    return parseToMoment(x.date).isBetween(oneDay, now);
                });
            case DocumentDateEnum.MORE_THAN_TWO_WEEKS:
                return data?.filter((x) => {
                    return x.date >= towWeeks;
                });
        }
    }

    function filterDataByType(filter: FilterModel, allData: MarketNewsDetailsModel[]): any[] {
        let data: MarketNewsDetailsModel[] = [];
        data = !allData && marketNewsAllData?.length > 0 ? marketNewsAllData : allData;

        const resultTab: any = [];
        if (data?.length > 0) {
            data?.map((x) => {
                if (x.type === filter.typeName) {
                    resultTab.push(x);
                }
            });
        }
        return resultTab;
    }

    function buildData(marketNewsModel: MarketNewsModel): MarketNewsDetailsModel[] {
        const marketNewsResult: MarketNewsDetailsModel[] = [];

        // New Listing
        if (marketNewsModel?.latestListings?.length > 0) {
            marketNewsModel.latestListings.map((x: any) => {
                marketNewsResult.push({
                    id: x?.id,
                    status: x?.status,
                    statusLabel: getNewListingStatusLabel(x),
                    isinCode: x?.isin,
                    date: x.listingDate,
                    label: "New listings",
                    type: MarketNewsFilterEnum.newListing,
                    currency: x?.currency,
                    amount: x?.issuedNominalAmount,
                    name: x?.instrumentName,
                    tradingCode: x?.tradingCode,
                    delistingDate: x?.delistingDate
                });
            });
            marketNewsFilterDraft.push({
                type: "typeNews",
                typeName: MarketNewsFilterEnum.newListing,
                name: "New listings",
                totalHits: marketNewsModel.latestListings.length,
            });
        }

        // Suspensions
        if (marketNewsModel?.suspensions?.length > 0) {
            const reason = "Pending withdrawal";
            let data = marketNewsModel.suspensions.sort((s1: any, s2: any) => {
                const date1 = new Date(s1.suspensionDate);
                const date2 = new Date(s2.suspensionDate);

                if (s1.suspensionReason != reason && s2.suspensionReason != reason) {
                    return date1.getTime() - date2.getTime();
                } else if (s1.suspensionReason != reason)
                    return -1;
                else if (s2.suspensionReason != reason)
                    return 1;
                else
                    return date1.getTime() - date2.getTime();
            });
            data.map((x: any) => {
                marketNewsResult.push({
                    id: x?.id,
                    status: x?.status,
                    statusLabel: setSuspensionReason(x?.suspensionReason),
                    isinCode: x?.isin,
                    date: x?.suspensionDate,
                    label: "Latest suspensions",
                    type: MarketNewsFilterEnum.suspensions,
                    currency: x?.currency,
                    amount: x?.issuedNominalAmount,
                    name: x?.instrumentName,
                });
            });
            marketNewsFilterDraft.push({
                type: "typeNews",
                typeName: MarketNewsFilterEnum.suspensions,
                name: "Latest suspensions",
                totalHits: marketNewsModel.suspensions.length,
            });
        }

        // Transfers
        if (marketNewsModel?.marketTransfer?.length > 0) {
            marketNewsModel.marketTransfer.map((x: any) => {
                marketNewsResult.push({
                    id: x?.id,
                    status: x?.status,
                    statusLabel: marketTransfersLabel(x?.marketTransfers[0]?.marketBefore.trim()) + " -> " + marketTransfersLabel(x?.marketTransfers[0]?.marketAfter.trim()),
                    isinCode: x?.isin,
                    date: x?.marketTransfers[0]?.changeUtcDt,
                    label: "Market transfers",
                    type: MarketNewsFilterEnum.transfers,
                    currency: x.currency,
                    amount: x.issuedNominalAmount,
                    name: x.instrumentName,
                });
            });
            marketNewsFilterDraft.push({
                type: "typeNews",
                typeName: MarketNewsFilterEnum.transfers,
                name: "Market transfers",
                totalHits: marketNewsModel?.marketTransfer?.length,
            });
        }

        // Upcoming Delisting
        if (marketNewsModel?.upcomingDelisting?.length > 0) {
            marketNewsModel.upcomingDelisting.map((x: any) => {
                marketNewsResult.push({
                    id: x?.id,
                    status: x?.status,
                    statusLabel: securityDelistingReasonLabel(x.delistingReason),
                    isinCode: x.isin,
                    date: x?.delistingDate,
                    label: "Upcoming delistings",
                    type: MarketNewsFilterEnum.upcomingDelisting,
                    currency: x.currency,
                    amount: x.issuedNominalAmount,
                    name: x.instrumentName,
                });
            });
            marketNewsFilterDraft.push({
                type: TYPE_NEWS,
                typeName: MarketNewsFilterEnum.upcomingDelisting,
                name: "Upcoming delistings",
                totalHits: marketNewsModel.upcomingDelisting.length,
            });
        }


        // New Admission To Trading
        // Latest admission to trading
        if (marketNewsModel?.latestAdmissionToTrading?.length > 0) {
            marketNewsModel.latestAdmissionToTrading.map((x: any) => {
                marketNewsResult.push({
                    id: x?.id,
                    status: x.status,
                    statusLabel: securityDelistingReasonLabel(x.delistingReason),
                    isinCode: x.isin,
                    date: x?.listingDate,
                    label: "New tradable securities",
                    type: MarketNewsFilterEnum.newAdmissionToTrading,
                    currency: x.currency,
                    amount: x.issuedNominalAmount,
                    name: x.instrumentName,
                });
            });
            marketNewsFilterDraft.push({
                type: TYPE_NEWS,
                typeName: MarketNewsFilterEnum.newAdmissionToTrading,
                name: "New tradable securities",
                totalHits: marketNewsModel.latestAdmissionToTrading.length,
            });
        }


        // Upcoming Removal From Trading
        if (marketNewsModel?.upcomingRemovalFromTrading?.length > 0) {
            marketNewsModel.upcomingRemovalFromTrading.map((x: any) => {
                marketNewsResult.push({
                    id: x?.id,
                    status: x?.status,
                    statusLabel: securityDelistingReasonLabel(x.delistingReason),
                    isinCode: x?.isin,
                    date: x?.delistingDate,
                    label: "Upcoming removals from trading",
                    type: MarketNewsFilterEnum.upcomingRemovalFromTrading,
                    currency: x.currency,
                    amount: x.issuedNominalAmount,
                    name: x.instrumentName,
                });
            });
            marketNewsFilterDraft.push({
                type: TYPE_NEWS,
                typeName: MarketNewsFilterEnum.upcomingRemovalFromTrading,
                name: "Upcoming removals from trading",
                totalHits: marketNewsModel.upcomingRemovalFromTrading.length,
            });
        }


        // CSSF Approvals
        if (marketNewsModel?.cssfApprovals?.length > 0) {
            marketNewsModel.cssfApprovals.map((x: any) => {
                marketNewsResult.push({
                    id: x?.id,
                    status: "CSSF",
                    date: x?.publishDate,
                    label: "CSSF Approvals",
                    type: MarketNewsFilterEnum.cssf,
                    name: x?.complement,
                    properties: {
                        type: x?.documentTypeCode,
                        typePublic: x?.documentPublicTypeCode,
                        description: x?.description,
                        downloadUrl: x?.downloadUrl,
                        previewUrl: x?.downloadUrl,
                        hostMemberStates: x?.hostMemberStates,
                        categories: x?.categories,
                        language: x?.language,
                        referenceDate: x?.referenceDate,
                        supplementDocuments: x?.supplementDocuments,
                        docIncorporesParRef: x?.incorporedByReferenceDocuments.length > 0 ? x?.incorporedByReferenceDocuments : undefined,
                    },
                });
            });
            marketNewsFilterDraft.push({
                type: "typeNews",
                typeName: MarketNewsFilterEnum.cssf,
                name: "CSSF Approvals",
                totalHits: marketNewsModel.cssfApprovals.length,
            });
        }

        // FNS
        if (marketNewsModel?.fns?.length > 0) {
            marketNewsModel.fns.map((x: any) => {
                marketNewsResult.push({
                    id: x?.id,
                    status: "FNS",
                    date: x?.publishDate,
                    label: "Issuer and LuxSE notices (FNS)",
                    type: MarketNewsFilterEnum.fns,
                    name: x?.complement,
                    properties: {
                        type: x?.documentTypeCode,
                        typePublic: x?.documentPublicTypeCode,
                        description: x?.description,
                        downloadUrl: x?.downloadUrl,
                        previewUrl: x?.downloadUrl,
                        categories: x?.categories,
                        language: x?.language,
                        referenceDate: x?.referenceDate,
                        depositor: x?.depositor,
                        issuers: x?.issuers,
                    },
                });
            });
            marketNewsFilterDraft.push({
                type: TYPE_NEWS,
                typeName: MarketNewsFilterEnum.fns,
                name: "Issuer and LuxSE notices (FNS)",
                totalHits: marketNewsModel.fns.length
            });
        }


        return marketNewsResult;
    }

    const fetchMarketNewsData =  async () => {
        let marketNewsData = new MarketNewsModel();
        try {
            const marketTransfer = await client.query({
                query: GET_SECURITIES_MARKET_TRANSFER,
                variables: {
                    pageNumber: 0,
                    pageSize: 0,
                },
                fetchPolicy:"network-only",
                errorPolicy:"all"
            });

            if( marketTransfer?.errors != null )
                console.error("Errors when get market transfers ", marketTransfer?.errors);

            const suspensions = await client.query({
                query: GET_LATEST_SUSPENSIONS,
                variables: {
                    pageNumber: 0,
                    pageSize: 0,
                },
                fetchPolicy:"network-only",
                errorPolicy:"all"
            });

            if( suspensions?.errors != null )
                console.error("Errors when get latest suspensions ", suspensions?.errors);

            const latestListings = await client.query({
                query: GET_LATEST_LISTINGS,
                variables: {
                    pageNumber: 0,
                    pageSize: 0,
                },
                fetchPolicy:"network-only",
                errorPolicy:"all"
            });

            if( latestListings?.errors != null )
                console.error("Errors when get latest listings ", latestListings?.errors);

            const cssfApprovals = await client.query({
                query: GET_LATEST_CSSF_APPROVAL,
                variables: {
                    pageNumber: 0,
                    pageSize: 0,
                },
                fetchPolicy:"network-only",
                errorPolicy:"all"
            });

            if( cssfApprovals?.errors != null )
                console.error("Errors when get CSSF approvals ", cssfApprovals?.errors);

            const fns = await client.query({
                query: GET_LATEST_FNS,
                variables: {
                    pageNumber: 0,
                    pageSize: 0,
                },
                fetchPolicy:"network-only",
                errorPolicy:"all"
            });

            if( fns?.errors != null )
                console.error("Errors when get latest FNS ", fns?.errors);

            const upcomingDelisting = await client.query({
                query: GET_UPCOMING_DELISTINGS,
                variables: {
                    pageNumber: 0,
                    pageSize: 0,
                },
                fetchPolicy:"network-only",
                errorPolicy:"all"
            });

            if( upcomingDelisting?.errors != null )
                console.error("Errors when get upcoming delisting ", upcomingDelisting?.errors);

            const latestAdmissionToTrading = await client.query({
                query: GET_LATEST_ADMISSION_TO_TRADING,
                variables: {
                    pageNumber: 0,
                    pageSize: 0,
                },
                fetchPolicy:"network-only",
                errorPolicy:"all"
            });

            if( latestAdmissionToTrading?.errors != null )
                console.error("Errors when get latest admissions to trading ", latestAdmissionToTrading?.errors);

            const upcomingRemovalFromTrading = await client.query({
                query: GET_UPCOMING_REMOVAL_FROM_TRADING,
                variables: {
                    pageNumber: 0,
                    pageSize: 0,
                },
                fetchPolicy:"network-only",
                errorPolicy:"all"
            });

            if( upcomingRemovalFromTrading?.errors != null )
                console.error("Errors when get upcoming removals ", upcomingRemovalFromTrading?.errors);

            marketNewsData = {
                latestListings: latestListings?.data?.securitiesLatestListing,
                cssfApprovals: cssfApprovals?.data?.latestCssfApproval,
                fns: fns?.data?.latestFNS,
                marketTransfer: marketTransfer?.data?.securitiesLatestMarketTransfer,
                suspensions: suspensions?.data?.securitiesLatestSuspension,
                upcomingDelisting: upcomingDelisting?.data?.securitiesUpcomingDelistings,
                latestAdmissionToTrading: latestAdmissionToTrading?.data?.securitiesLatestAdmissionToTrading,
                upcomingRemovalFromTrading: upcomingRemovalFromTrading?.data?.securitiesUpcomingRemovalFromTrading,
            };

            const result = buildData(marketNewsData);
            setMarketNewsAllData(result);
            setMarketNewsFilter(marketNewsFilterDraft);

            if (filterFromDashboard) {
                let filter: FilterModel = {
                    type: TYPE_NEWS,
                    typeName: filterFromDashboard,
                    name: filterFromDashboard.toString(),
                    totalHits: null,
                };

                marketNewsFilterDraft.forEach((data) => {
                    if (data.typeName === filterFromDashboard) filter = data;
                });

                changeTypeFilter(filter, result);
            }
        } catch (error) {
            console.error("Failed to get market news data", error);
            // TODO: change to error handling page
            // goToNotFoundPage();
        }
    };

    useEffect(() => {
        fetchMarketNewsData().catch()
    }, []);

    useEffect(() => {
        setMarketNewsFilteredData(marketNewsAllData);
    }, [setMarketNewsFilteredData, marketNewsAllData]);

    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

    const switchFilter = () => {
        setMenuIsOpen(!menuIsOpen);
    };

    return (
        <div>
            <SearchInput onSearchInputUpdate={null} routeTextInput={null}/>
            <Loader loading={!marketNewsFilteredData}>
                <div css={genericStyle.desktopDisplay}>
                    <MarketNewsTagFilter
                        nbResults={marketNewsFilteredData?.length}
                        filterType={typeFilter?.name}
                        filterDate={dateFilter}
                        onFilterClear={null}
                        onReset={null}
                        handelClick={removeFilter}
                        label={"Market News"}
                    />
                    <div css={marketNewsStyle.tabs}>
                        <div css={marketNewsStyle.tabContentWrapper}>
                            <div css={marketNewsStyle.tabContainer}>
                                <div css={marketNewsStyle.searchResultsFilters}>
                                    <MarketNewsMenuRigthFilters
                                        filters={typeFilter?.name}
                                        marketNewsFilter={marketNewsFilter?.filter((x) => x?.type === TYPE_NEWS)}
                                        handelClick={changeTypeFilter}
                                    />
                                    <CalendarFilterComponent
                                        value={dateFilter}
                                        marketNewsFilter={marketNewsFilter?.filter((x) => x?.type === TYPE_DATE)}
                                        handelClick={changeDateFilter}
                                    />
                                </div>
                                <div css={marketNewsStyle.tabContainer}>
                                    <SearchTable
                                        loadingPage={false}
                                        data={
                                            marketNewsFilteredData?.length > 20
                                                ? createPage(marketNewsFilteredData)
                                                : marketNewsFilteredData
                                        }
                                        density={null}
                                        onPaginationUpdate={onPaginationUpdate}
                                        pageNumber={currentPageNumber}
                                        totalResultSize={marketNewsFilteredData?.length}
                                        Header={() => <Thead/>}
                                        Row={(row) => <MarketNewsRow row={row} key={`market-row-${row?.id}`}/>}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div css={genericStyle.mobileDisplay}>
                    <ResultsIndicator
                        category="Market News"
                        filters={{q: typeFilter?.name}}
                        resultsNumber={marketNewsFilteredData?.length}
                    />
                    <div css={{margin: "2em"}}>
                        <SearchMobile
                            onPaginationUpdate={onPaginationUpdate}
                            pageNumber={currentPageNumber}
                            data={
                                marketNewsFilteredData?.length > 20
                                    ? createPage(marketNewsFilteredData)
                                    : marketNewsFilteredData
                            }
                            totalResultSize={marketNewsFilteredData?.length}
                            Card={(card) => <MarketNewsRow row={card} mobile={true} key={`market-card-${card?.id}`}/>}
                        />
                    </div>

                    <FilterButton onSwitchFilter={switchFilter} open={menuIsOpen}/>
                    <MobileFilterContainer open={menuIsOpen}>
                        <MarketNewsMenuRigthFilters
                            filters={typeFilter}
                            marketNewsFilter={marketNewsFilter?.filter((x) => x?.type === TYPE_NEWS)}
                            handelClick={changeTypeFilter}
                        />
                        <CalendarFilterComponent
                            value={typeFilter}
                            handelClick={changeDateFilter}
                        />
                    </MobileFilterContainer>
                </div>
            </Loader>
        </div>
    );
}

export default MarketNewsView;
