/* eslint-disable */
import * as React from "react";
import { luxseGatewayGraphqlClient } from "../../../luxse-gateway-graphql-client";
import { GET_SEARCH_WEB_PAGE_RESULTS } from "../../../graphql/queries/search/search-webpage.queries";
import Utils from "../../utils/utils";
import { LuxseSearchWebPageResults } from "../../../interfaces/search/search-web-pages-results";
import { Filters } from "../../components/Search/useFilters";
import { SearchResultsDto } from "../../../interfaces";

export type useSearchWebPageProps = {
  searchTerm: string;
  size?: number;
  page?: number;
  sort?: string;
  categories: string[];
  subCategories: string[];
  tags: string[];
  authors: string[];
  fromdate?: Date;
  todate?: Date;
};

const DEFAULT_SORT_FIELD = "";
const DEFAULT_SORT_DIR = "DESC";

const createSearchResultFromWebpage = (result: LuxseSearchWebPageResults) => {
  if (result == null)
    return {
      totalHits: 0,
      webPagesSearch: null,
      luxseSecuritiesSearch: null,
      luxXPrimeSearch: null,
      luxseIssuersSearch: null,
      luxseProgrammesSearch: null,
      luxseDocumentsSearch: null,
      luxseIndexesSearch: null,
    } as SearchResultsDto;
  return {
    totalHits: result.totalHits,
    webPagesSearch: result,
    luxseSecuritiesSearch: null,
    luxXPrimeSearch: null,
    luxseIssuersSearch: null,
    luxseProgrammesSearch: null,
    luxseDocumentsSearch: null,
    luxseIndexesSearch: null,
  } as SearchResultsDto;
};

  

const useSearchContent = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const search = React.useCallback(
    async (
      filters: Filters,
      itemsNumber: number,
      currentPageNumber: number,
    ) => {
      try {
        setIsLoading(true);
        const categoryFilter = filters.dataType === "all" ? [] : [filters.dataType];
        const results: any = await luxseGatewayGraphqlClient.query({
          query: GET_SEARCH_WEB_PAGE_RESULTS,
          variables: {
            token: Utils.getCookie("Gztoken-Dsm"),
            searchTerm: filters.q,
            size: itemsNumber,
            page: currentPageNumber,
            categories: categoryFilter,
            subCategories: [],
            tags: [],
            authors: [],
            fromdate: null,
            todate: null,
            ...filters,
          },
          fetchPolicy:"network-only",
          errorPolicy:"all"
        });

        if( results?.errors != null )
            console.error("Errors when processing search ", results?.errors);

        if (results && results.data) {
          return createSearchResultFromWebpage(
            results.data.luxseWebPagesSearch
          );
        }
      } catch (e) {
        console.error("Failed to get search data", e);
      } finally {
        setIsLoading(false);
      }
      return {
        totalHits: 0,
        webPagesSearch: null,
        luxseSecuritiesSearch: null,
        luxXPrimeSearch: null,
        luxseIssuersSearch: null,
        luxseProgrammesSearch: null,
        luxseDocumentsSearch: null,
        luxseIndexesSearch: null,
      } as SearchResultsDto;
    },
    [isLoading, setIsLoading]
  );

  return { isLoading, search };
};

export default useSearchContent;
